/* eslint-disable camelcase, no-undef, no-underscore-dangle, no-console */

// We're injecting the public path into a meta tag so we don't need to adjust the CSP
const publicPathEl = document.querySelectorAll('meta[name="enablo-public-path"]');
const publicPath = publicPathEl && publicPathEl.length > 0 && publicPathEl[0].getAttribute('content');

// Set the __webpack_public_path__ and the application variable
__webpack_public_path__ = `${publicPath || ''}/`;
window.__enablo_wam_public_path__ = publicPath;

export default publicPath;
