import '../public-path';

import * as Sentry from '@sentry/browser';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

import Load from './load';

if (process.env.SENTRY_ENVIRONMENT) {
  Sentry.init({
    dsn: 'https://adcb858b8d984cefb561debbe8d1e891@sentry.io/1504684',
    environment: process.env.SENTRY_ENVIRONMENT,
  });
  // eslint-disable-next-line no-console
  if (console && console.log) console.log(`Sentry loaded (${process.env.SENTRY_ENVIRONMENT}).`);
}

ReactDOM.render(
  <Suspense fallback="...">
    <Load />
  </Suspense>,
  document.getElementById('app'),
);
