import React from 'react';
import Loadable from 'react-loadable';

import Loader from './components/Loader';

import './scss/loader.scss';

const LoadableComponent = Loadable({
  loader: () => import('./app'),
  loading: Loader,
});

const Load = () => (
  <LoadableComponent />
);

export default Load;
